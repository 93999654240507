import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Row, Col } from "react-bootstrap"
import SEO from "../components/seo"

import Layout from "../components/layout/layout"
import InnerWrapper from "../components/layout/innerWrapper"
import SearchModule from "../components/search/searchModule"

import HEADER from "../images/default-header.jpg"

const PortfolioList = (props) => {
  const {
    categorySlug,
    category,
    subCategory,
    subCategorySlug,
    use,
    useSlug,
    material,
    materialSlug,
    style,
    styleSlug,
    id,
    type,
    typeSlug
  } = props.pageContext

  const customProjectHeaders = useStaticQuery(graphql`
    {
      allPortfolioHeader {
        nodes {
          content
          categorySlug
          imagePaths
          subCategory
          use
          material
          style
          id
          seoTitle
          seoDescription
          portfolioHeader
          title
          type
        }
      }
    }
  `)

  let customProjectHeader = customProjectHeaders.allPortfolioHeader.nodes.find(
    (header) => header.id === id
  )

  const headerImage = customProjectHeader
    ? customProjectHeader.imagePaths[0]
    : HEADER

  const Hit = ({ hit }) => (
    <article>
      <Col key={hit.title} className="item portfolio-th mob-thfix">
        <Link className="_link" to={hit.slug}></Link>
        <figure
          className="project-thumb"
          style={{
            background: `url(${hit.imageUrl})`,
          }}
        ></figure>
        <div className="overlay overlaytext">
          <h4>{hit.title}</h4>
        </div>
      </Col>
    </article>
  )

  return (
    <Layout>
      <SEO
        title={customProjectHeader.seoTitle || subCategory || category}
        description={customProjectHeader.seoDescription || `Since 1933, Walpole Outdoors products have been the pride of homeowners. View our selection of AZEK fences, pergolas, arbors, gates & more.`}
        imageUrl={headerImage}
      />
      <InnerWrapper>
        <div
          className="page-hero"
          style={{
            backgroundImage: `url(${headerImage})`,
          }}
        ></div>

        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/custom-projects">Custom Projects</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to={`/custom-projects/${categorySlug}`}>{category}</Link>
          </Breadcrumb.Item>
          {subCategory && (
            <Breadcrumb.Item>
              <Link to={`/custom-projects/${categorySlug}/${subCategorySlug}`}>
                {subCategory}
              </Link>
            </Breadcrumb.Item>
          )}
        </Breadcrumb>

        <Row className="portfolio-header">
          <h1 className="generalheader">{customProjectHeader.title || customProjectHeader.portfolioHeader || subCategory || category}</h1>
          <div
            className="header-description"
            dangerouslySetInnerHTML={{
              __html: customProjectHeader ? (
                customProjectHeader.content
              ) : (
                <div />
              ),
            }}
          />
        </Row>
        <Row>
          <Col md={12}>
            <SearchModule
              showTitle={false}
              showSelector={false}
              showProjects={true}
              categoryName={category}
              subCategoryName={subCategory}
              hitComponent={Hit}
              use={use}
              style={style}
              material={material}
              type={type}
            />
            <div
              className="pagination"
              style={{ justifyContent: "space-between" }}
            ></div>
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default PortfolioList
